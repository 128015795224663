import * as React from "react";

type props = {
  color: string;
  className?: string;
};
const Twitter = ({ color, className }: props) => {
  return (
    <svg
      className={className}
      width="21"
      height="17"
      viewBox="0 0 21 17"
      fill="none"
    >
      <path
        d="M18.2775 4.71489C18.2902 4.89255 18.2902 5.07024 18.2902 5.2479C18.2902 10.6666 14.1658 16.9103 6.62776 16.9103C4.30541 16.9103 2.14807 16.2377 0.333344 15.0702C0.663304 15.1083 0.980531 15.121 1.32319 15.121C3.2394 15.121 5.00338 14.4738 6.41201 13.3697C4.60998 13.3316 3.09983 12.1515 2.57952 10.5271C2.83334 10.5651 3.08713 10.5905 3.35366 10.5905C3.72166 10.5905 4.08971 10.5397 4.43233 10.4509C2.55416 10.0702 1.14549 8.42048 1.14549 6.42809V6.37735C1.69116 6.68192 2.32573 6.87227 2.99827 6.89762C1.8942 6.16157 1.17088 4.90524 1.17088 3.48391C1.17088 2.72251 1.37389 2.02454 1.72924 1.4154C3.74701 3.9027 6.78002 5.52704 10.181 5.70473C10.1176 5.40016 10.0795 5.08294 10.0795 4.76567C10.0795 2.50677 11.9069 0.666687 14.1785 0.666687C15.3587 0.666687 16.4246 1.16161 17.1734 1.9611C18.0997 1.78344 18.9881 1.44079 19.7749 0.971257C19.4703 1.92305 18.8231 2.72255 17.9729 3.23012C18.7978 3.14134 19.5972 2.91286 20.3333 2.59563C19.775 3.40778 19.077 4.1311 18.2775 4.71489Z"
        fill={color}
      />
    </svg>
  );
};

Twitter.defaultProps = {
  color: "#ffffff",
};

export default Twitter;
